define(['jquery', 'app', 'siteObj'], function($, app, siteObj) {
  
  var _trustpilot = {

    _init: function() {
      var trustPilotAccountId = siteObj.features.trustPilotAccountId;
      var tplinkURL = siteObj.features.trustPilotAccountURL;
      if (trustPilotAccountId && tplinkURL) {
        app.subscribe('trustpilot/run', function() {
          _trustpilot._load(trustPilotAccountId, tplinkURL);
        });

        if (document.querySelector('[data-component="trustpilot"]')) {
          _trustpilot._load(trustPilotAccountId, tplinkURL);
        }
      }
    },

    _load: function(trustPilotAccountId, tplinkURL) {
      $.ajax({
        url: 'https://s.trustpilot.com/tpelements/' + trustPilotAccountId + '/f.jsonp',
        dataType: 'jsonp',
        jsonp: false,
        jsonpCallback: 'trustpilot_jsonp_callback',
        success: function(data, status) {
          if (data) {
            if (data.TrustScore.Stars > 3) {
              $('[data-component=\'trustpilot\']').html('<a href="' + tplinkURL + ' " target="_blank" rel="nofollow"><img src=' + data.TrustScore.StarsImageUrls.large + ' /><p>' + siteObj.customerReviews.tpTextStart + ' ' + data.ReviewCount.Total + ' ' + siteObj.customerReviews.tpTextEnd + '</a>');
            } else {
              $('.trustpilot').css('display', 'none');
            }
          }
        },

        error: function(XHR, txtStatus, errThrown) {
          console.log('Error: ' + txtStatus);
          console.log('Error: ' + errThrown);
        }
      });
    }
  };

  _trustpilot._init();
  return _trustpilot;
});
